import React from 'react';
import { Translation  } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ButtonSpinner from '../spinners/ButtonSpinner';
import ErrorModal from '../modals/ErrorModal';
import Form from 'react-bootstrap/Form';
import ListSaveToFormModal from '../modals/ListSaveToFormModal';
import ListFormModal from '../modals/ListFormModal';
import Select, { createFilter } from 'react-select'
import debounce from 'lodash.debounce';
import queryString  from 'query-string';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import RequirementDetailsModal from '../modals/RequirementDetailsModal';
import RequirementsPagination from './RequirementsPagination';

import MarkdownView from 'react-showdown';

import SortableTableHeader from './SortableTableHeader';
import { requirementsService } from '../../services/requirementsService';
import { authenticationService } from '../../services/authenticationService';
import { myListCounterService } from '../../services/myListCounterService';

import { SiteConfig }  from '../../SiteConfig';

class RequirementsGrid extends React.Component {

  constructor(props) {

      super(props);

      this.state = {
        error: null,
        initiallyLoaded: false,
        loadingData: false,
        areFiltersLoaded: false,
        page_size: process.env.REACT_APP_REQUIREMENTS_RESULTS_PER_PAGE,
        cm_topics: [],
        cm_stakeholders: [],
        cm_rmes: [],
        cm_qmps: [],
        cm_standards: [],
        doc_type: null,
        selected_req_id: null,
        selected_req_id_timestamp: null,
        selected_topics: [],
        selected_stakeholders: [],
        selected_rmes: [],
        selected_qmps: [],
        text_search: '',
        selected_standards: [],
        //selected_strengths: ['should','shall','may'],
        selected_strengths: [],
        sort_column: 'standard_number',
        sort_type: 'asc',
        selected_page: 1,
        requirements_only: false,
        numResults: 0,
        page: 0,
        resultsPerPage: 0,
        pagesTotal: 0,
        allQMPPrinciples: [],
        allRMElements:[],
        allTopics: [],
        allStakeholders: [],
        allStandards: [],
        requirements: [],
        cmLists: [],
        save_row_hover: [],
        row_updating: [],
        save_all_updating: false,
        show_toast : false,
        cm_standards_input_value: '',
        cm_topics_input_value: '',
        cm_stakeholders_input_value: '',
        cm_qmps_input_value: '',
        cm_rmes_input_value: '',
        window_location : window.location+window.location.search,
        showErrorModal: false,
        showListSaveToFormModal: false,
        showListFormModal: false,
        selectedListReqIds: [],
        canViewLinkedRequirements: false
      };
      this.handleSortColumn = this.handleSortColumn.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.handleClearAll = this.handleClearAll.bind(this);
      this.handleShare = this.handleShare.bind(this);
      this.toggleShowToast = this.toggleShowToast.bind(this);
      this.handleSaveClick = this.handleSaveClick.bind(this);
      this.handleSaveAllClick = this.handleSaveAllClick.bind(this);
      this.fetchRequirementsData = this.fetchRequirementsData.bind(this);
      this.paginationEventHandler = this.paginationEventHandler.bind(this);
      this.pageSizeEventHandler = this.pageSizeEventHandler.bind(this);
      this.lowerPaginationEventHandler = this.lowerPaginationEventHandler.bind(this);
      this.lowerPageSizeEventHandler = this.lowerPageSizeEventHandler.bind(this);

      this.fetchFilterData = this.fetchFilterData.bind(this);
      this.handleDegreesOfCompliance = this.handleDegreesOfCompliance.bind(this);
      this.determineDisabledOptions  = this.determineDisabledOptions.bind(this);
      this.onTextSearchChange = this.onTextSearchChange.bind(this);
      this.updateRowStatus = this.updateRowStatus.bind(this);
      this.replaceQueryString = this.replaceQueryString.bind(this);
      this.onInputChange = this.handleInputChanged.bind(this);
      this.onCloseErrorModalCallback = this.onCloseErrorModalCallback.bind(this);
      this.showErrorModal = this.showErrorModal.bind(this);
      this.navigateToLinkPage = this.navigateToLinkPage.bind(this);
      this.saveHoverStatus = this.saveHoverStatus.bind(this);
      this.onCloseShowListSaveToFormModalCallback = this.onCloseShowListSaveToFormModalCallback.bind(this);
      this.onCloseListFormModalCallback = this.onCloseListFormModalCallback.bind(this);
      this.showListSaveToForm = this.showListSaveToForm.bind(this);
      this.popOverContainerRef = React.createRef();
      this.upperPaginationRef = React.createRef();
      this.locationarea = React.createRef();

    }

    showListSaveToForm = (e, req_id) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        showListSaveToFormModal: true,
        selectedListReqIds: [req_id]
      });
    }


    onCloseShowListSaveToFormModalCallback = (reloadData,showAddListModal) => {
      this.setState({
        showListSaveToFormModal: false,
        showListFormModal:showAddListModal
      });
    };

    onCloseListFormModalCallback = (reloadData) => {
      if (reloadData) {
        this.setState({
          selectedReqId: this.state.selectedReqId
        });
      }

      this.setState({
        showListSaveToFormModal: true,
        showListFormModal:false
      });


    }

    noMorePsExt  = {
      type: 'output',
      filter: function(text, converter) {
        var re = /<\/?p[^>]*>/ig;
        text = text.replace(re, '');
        return text;
      }
    }



    badgeVariant(type) {

      var opts = {};
      var variant = '';

      switch (type.trim()) {
        case 'Shall':
          variant = 'danger';
          break;
        case 'Should':
          variant = 'success';
          break;
        case 'May':
            variant = 'info';
            break;
        case 'Informative':
            variant = 'informative';
            break;
        default:
            variant = 'info';
      }

      opts['variant'] = variant;

      return opts;

    }

    fetchRequirementsData(scrollToTop) {

      this.setState({
        loadingData: true
      });

      let data = new FormData();
      data.append('doc_type',SiteConfig.docType);
      data.append('text_search',this.state.text_search);
      data.append('selected_standards',this.state.selected_standards);
      data.append('selected_topics',this.state.selected_topics);
      data.append('selected_stakeholders',this.state.selected_stakeholders);
      data.append('selected_rmes',this.state.selected_rmes);
      data.append('selected_qmps',this.state.selected_qmps);
      data.append('selected_strengths',this.state.selected_strengths);
      data.append('selected_page',this.state.selected_page);
      data.append('page_size',this.state.page_size);
      data.append('sort_column',this.state.sort_column);
      data.append('sort_type',this.state.sort_type);

      requirementsService.requirements(data)
        .then(
          (result) => {

            this.setState({
              initiallyLoaded: true,
              loadingData: false,
              numResults: result.numResults,
              page: result.page,
              resultsPerPage: result.resultsPerPage,
              pagesTotal: result.pagesTotal,
              allQMPPrinciples: result.allQMPPrinciples,
              allRMElements: result.allRMElements,
              allStakeholders: result.allStakeholders,
              allTopics: result.allTopics,
              allStandards: result.allStandards,
              cmLists: result.Lists,
              requirements: result.Requirements.data,
              canViewLinkedRequirements: result.isAmasAdmin,

            }, function() {
              if (!this.state.areFiltersLoaded) {
                //get the filter data
                this.fetchFilterData();
              }
              //persist the state in the query string
              this.replaceQueryString();



            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if (error.message === '401') {
              authenticationService.logout();
            } else if (error.message === '500') {
              this.setState({
                loadingData: false,
              });
              this.showErrorModal();
            } else {
              this.setState({
                loadingData: false,
                error
              });
            }
          }
        )
    }

    navigateToLinkPage(e, id) {
      e.preventDefault();
      e.stopPropagation();
      this.props.history.push('/links/' + id);
    }

    onCloseErrorModalCallback() {
      this.setState({
        showErrorModal : false
      });
    }

    showErrorModal() {
      this.setState({
        showErrorModal : true
      });
    }

    handleInputChanged(input, reason ,name) {

     if (reason.action === "set-value") {
       return;
     }

     switch (name) {
       case 'cm_topics':
         this.setState({
           cm_topics_input_value: input
         });
         break;
       case 'cm_stakeholders':
         this.setState({
           cm_stakeholders_input_value: input
         });
         break;
       case 'cm_rmes':
         this.setState({
           cm_rmes_input_value: input
         });
         break;
       case 'cm_qmps':
         this.setState({
           cm_qmps_input_value: input
         });
         break;
       case 'cm_standards':
         this.setState({
           cm_standards_input_value: input
         });
         break;
       default:
     };
    }

    handleShare =  (e) => {

      e.preventDefault();
      e.stopPropagation();

      navigator.clipboard.writeText(this.state.window_location);

      this.toggleShowToast(e);
    }

    toggleShowToast =  (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.setState({
        show_toast: !this.state.show_toast
      });
    }

    handleClearAll =  (e) => {
      e.preventDefault();
      e.stopPropagation();

      document.getElementById("text_search").value = '';

      this.setState({
        selected_topics: [],
        selected_stakeholders: [],
        selected_rmes: [],
        selected_qmps: [],
        text_search: '',
        selected_standards: [],
        selected_strengths: [],
        sort_column: 'standard_number',
        sort_type: 'asc',
        selected_page: 1,
        requirements_only: false,
        cm_topics_select_values: [],
        cm_stakeholders_select_values: [],
        cm_rmes_select_values: [],
        cm_qmps_select_values: [],
        cm_standards_select_values: [],
      }, function() {
        this.fetchRequirementsData();
        this.replaceQueryString();
      });
    }

    onTextSearchChange = (event) => {
    /* signal to React not to nullify the event object */
        event.persist();

        if (!this.debouncedFn) {
          this.debouncedFn =  debounce(() => {
             let searchString = event.target.value;
             this.setState({
               requirements_only: false,
               selected_page: 1,
               text_search: searchString
             }, function() {
               this.fetchRequirementsData();
             });
          }, 500);
        }    this.debouncedFn();
    }

    fetchFilterData() {


      requirementsService.getSearchFilters(SiteConfig.docType,SiteConfig.siteTag)
          .then(
              (data) => {

                this.setState({
                  areFiltersLoaded: true,
                  cm_topics: data.topics.items,
                  cm_topics_select_values:  data.topics.items.filter(item => -1 < this.state.selected_topics.map(Number).indexOf(item.id)),
                  cm_stakeholders: data.stakeholders.items,
                  cm_stakeholders_select_values:  data.stakeholders.items.filter(item => -1 < this.state.selected_stakeholders.map(Number).indexOf(item.id)),
                  cm_rmes: data.rmes.items,
                  cm_rmes_select_values:  data.rmes.items.filter(item => -1 < this.state.selected_rmes.map(Number).indexOf(item.id)),
                  cm_qmps: data.qmps.items,
                  cm_qmps_select_values:  data.qmps.items.filter(item => -1 < this.state.selected_qmps.map(Number).indexOf(item.id)),
                  cm_standards: data.standards.items,
                  cm_standards_select_values:  data.standards.items.filter(item => -1 < this.state.selected_standards.map(Number).indexOf(item.id)),

                },function() {

                })
                //this.determineDisabledOptions();
              },
              error => {
                if (error.message === '401') {
                  authenticationService.logout();
                } else if (error.message === '500') {
                  this.showErrorModal();
                }
              }
          );
    }

    componentDidMount() {

      //read in state from query string
      const query = queryString.parse(window.location.search);

      let qsState = {};

      if (query.text) {
       qsState.text_search = query.text;
      }
      if (query.standards) {
       qsState.selected_standards = query.standards.split(',');
      }
      if (query.topics) {
       qsState.selected_topics = query.topics.split(',');
      }
      if (query.stakeholders) {
       qsState.selected_stakeholders = query.stakeholders.split(',');
      }
      if (query.rmes) {
       qsState.selected_rmes = query.rmes.split(',');
      }
      if (query.qmps) {
       qsState.selected_qmps = query.qmps.split(',');
      }
      if (query.stakeholders) {
       qsState.selected_stakeholders = query.stakeholders.split(',');
      }
      if (query.strengths) {
       qsState.selected_strengths = query.strengths.split(',');
      }
      if (query.page) {
       qsState.selected_page = query.page;
      }
      if (query.sort_column) {
       qsState.sort_column = query.sort_column;
      }
      if (query.page_size) {
       qsState.page_size = query.page_size;
      }

      if (query.sort_type) {
        qsState.sort_type = query.sort_type;
      }

      this.setState(qsState, function() {
        this.fetchRequirementsData();
      });



    }

    replaceQueryString() {

      let stateString = '';

      if (this.state.text_search !=='')
        stateString = stateString + "text=" + this.state.text_search + '&';

      if (0 < this.state.selected_standards.length)
        stateString = stateString + "standards=" + this.state.selected_standards.join(',') + '&';

      if (0 < this.state.selected_topics.length)
        stateString = stateString + "topics=" + this.state.selected_topics.join(',') + '&';

      if (0 < this.state.selected_rmes.length)
        stateString = stateString + "rmes=" + this.state.selected_rmes.join(',') + '&';

      if (0 < this.state.selected_qmps.length)
        stateString = stateString + "qmps=" + this.state.selected_qmps.join(',') + '&';

      if (0 < this.state.selected_stakeholders.length)
        stateString = stateString + "stakeholders=" + this.state.selected_stakeholders.join(',') + '&';

      if (0 !== this.state.selected_strengths.length)
        stateString = stateString + "strengths=" + this.state.selected_strengths.join(',') + '&';

      if (1 !== this.state.selected_page)
        stateString = stateString + "page=" + this.state.selected_page + '&';

      if ('standard_number' !== this.state.sort_column)
        stateString = stateString + "sort_column=" + this.state.sort_column + '&';

      if ("asc" !== this.state.sort_type)
        stateString = stateString + "sort_type=" + this.state.sort_type + '&';

      if (process.env.REACT_APP_REQUIREMENTS_RESULTS_PER_PAGE !== this.state.page_size)
        stateString = stateString + "page_size=" + this.state.page_size + '&'

      if (0 < stateString.length) {
        stateString = '?' + stateString.substring(0, stateString.length - 1);
      } else {
        stateString = window.location.href.split('?')[0];
      }
      window.history.replaceState({}, '', stateString);


      this.setState({
        window_location : -1 < window.location.href.indexOf('?') ? window.location.href : window.location.href+window.location.search
      });

    }


    handleDegreesOfCompliance(strength, checked) {

      var currentlySelectedStrengths = [...this.state.selected_strengths];
      if (checked) {
        currentlySelectedStrengths.push(strength);
      } else {
        const index = currentlySelectedStrengths.indexOf(strength);
        if (index > -1) {
          currentlySelectedStrengths.splice(index, 1);
        }
      }

      this.setState({
        requirements_only: false,
        selected_page: 1,
        selected_strengths: currentlySelectedStrengths
     }, function() {
       this.fetchRequirementsData();
     });

    }

    handleSortColumn(sortColumn, sortType) {
     this.setState({
       requirements_only: true,
       selected_page: 1,
       sort_column: sortColumn,
       sort_type: sortType
     }, function() {
       this.fetchRequirementsData();
     });
    }

    determineDisabledOptions  = () => {
      //once we have all the data we can disble options
      let returnTopics = this.state.cm_topics.map(x => (-1 < this.state.allTopics.indexOf(x.id) ? x : { ...x, disabled: 'yes' }));
      let returnStakeholders = this.state.cm_stakeholders.map(x => (-1 < this.state.allStakeholders.indexOf(x.id) ? x : { ...x, disabled: 'yes' }));
      let returnRmes = this.state.cm_rmes.map(x => (-1 < this.state.allRMElements.indexOf(x.id) ? x : { ...x, disabled: 'yes' }));
      let returnQmps = this.state.cm_qmps.map(x => (-1 < this.state.allQMPPrinciples.indexOf(x.id) ? x : { ...x, disabled: 'yes' }));
      let returnStandards = this.state.cm_standards.map(x => (-1 < this.state.allStandards.indexOf(x.id) ? x : { ...x, disabled: 'yes' }));

      this.setState({
        cm_topics : returnTopics,
        cm_stakeholders : returnStakeholders,
        cm_rmes : returnRmes,
        cm_qmps : returnQmps,
        cm_standards : returnStandards
      });
    }

    lowerPaginationEventHandler  = (page) => {
      this.paginationEventHandler(page,true);
      window.scrollTo({top: this.upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"})
    }

    paginationEventHandler  = (page,scrollToTop) => {
      this.setState({
        requirements_only: true,
        selected_page: parseInt(page,10)
      }, function() {
        this.fetchRequirementsData(scrollToTop);
      });
    }

    lowerPageSizeEventHandler  = (page) => {
      this.pageSizeEventHandler(page,true);
      window.scrollTo({top: this.upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"})

    }

    pageSizeEventHandler  = (page,scrollToTop) => {
      this.setState({
        requirements_only: true,
        selected_page: 1,
        page_size: page
      }, function() {
        this.fetchRequirementsData();
      });
    }

    handleSaveAllClick  = (e) => {
      e.preventDefault();
      e.stopPropagation();

      var reqIds = this.state.requirements.map(item => {
          //row_updating[item.id] = true;
          return item.id;
      });

      this.setState({
        showListSaveToFormModal: true,
        selectedListReqIds: reqIds
      });
    }

    saveHoverStatus = (value, index) => {
        const clone = [...this.state.save_row_hover];
        clone[index] = value;
        this.setState({
          save_row_hover: clone
        });
    }

    handleSaveClick = (e, id, is_saved_to_list) => {

      e.preventDefault();
      e.stopPropagation();
      //update the is_saved_to_list

      let row_updating = [...this.state.row_updating];
      row_updating[id] = true;
      this.setState({
          row_updating: row_updating
      });

      if (!is_saved_to_list) {

        requirementsService.putToList([id], SiteConfig.docType)
            .then(
                (data) => {
                  this.updateRowStatus(id, is_saved_to_list, row_updating);
                  myListCounterService.setMyListCount(data.count);
                },
                error => {}
            );
      } else {

        requirementsService.deleteFromList([id], SiteConfig.docType)
            .then(
                (data) => {
                  this.updateRowStatus(id, is_saved_to_list, row_updating);
                  myListCounterService.setMyListCount(data.count);
                },
                error => {}
            );
      }

    }

    updateRowStatus = (id, is_saved_to_list, row_updating) => {
      let requirements = [...this.state.requirements];
      var foundIndex = requirements.findIndex(x => x.id === id);
      requirements[foundIndex].is_saved_to_list = !is_saved_to_list;

      row_updating[id] = false;

      this.setState({
          row_updating: row_updating,
          requirements: requirements
      });
    }

    handleSelectChange = (selectedOption,ev) => {
      var stateItem = {
        requirements_only: false,
        selected_page: 1,
      };

      var newIds = []

      if (selectedOption) {
        newIds =  selectedOption.map(option => {
            return option.id;
        });
      }


      switch (ev.name) {
        case 'cm_topics':
          stateItem.selected_topics = newIds;
          stateItem.cm_topics_select_values = selectedOption;
          break;
        case 'cm_stakeholders':
          stateItem.selected_stakeholders = newIds;
          stateItem.cm_stakeholders_select_values = selectedOption;
          break;
        case 'cm_rmes':
          stateItem.selected_rmes = newIds;
          stateItem.cm_rmes_select_values = selectedOption;
          break;
        case 'cm_qmps':
          stateItem.selected_qmps = newIds;
          stateItem.cm_qmps_select_values = selectedOption;
          break;
        case 'cm_standards':
          stateItem.selected_standards = newIds;
          stateItem.cm_standards_select_values = selectedOption;
          break;
        default:
      };

      this.setState(
        stateItem,
        function() {
          this.fetchRequirementsData();
        }
      );

    }
    render() {
      const { error, numResults, text_search, selected_strengths , show_toast, window_location, initiallyLoaded, loadingData, sort_column, sort_type, page_size, requirements, cm_topics, cm_topics_select_values ,cm_stakeholders, cm_stakeholders_select_values ,cm_qmps, cm_qmps_select_values, cm_rmes, cm_rmes_select_values, cm_standards, cm_standards_select_values, cm_standards_input_value, cm_topics_input_value, cm_stakeholders_input_value, cm_qmps_input_value, cm_rmes_input_value, canViewLinkedRequirements } = this.state;

      const standardFilterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => `${option.data.number} ${option.data.title}`,
        trim: true
      };

      const renderStandardOption = (option: Readonly<Option>) => (
        <>
          {option.number && <strong>{option.number}</strong> } {option.title}
        </>
      )

      if (error) {
        return <div>Error: {error.message}</div>;
      } else {
        return (
          <Translation>
            {
              (t, { i18n }) =>
                <>
                { initiallyLoaded && loadingData &&
                  <ModalSpinner/>
                }  <input type="hidden" id="window_location" name="window_location" value={window_location}/>
                <ErrorModal showerrormodal={this.state.showErrorModal} onCloseErrorModal={this.onCloseErrorModalCallback} />
                <ListSaveToFormModal show={this.state.showListSaveToFormModal} req_ids={this.state.selectedListReqIds} handleModalOpenClose={this.onCloseShowListSaveToFormModalCallback} />
                <ListFormModal show={this.state.showListFormModal}  handleModalClose={this.onCloseListFormModalCallback} />

                { initiallyLoaded &&
                <div className="controls">
                  <div className="container">
                     <div className="col">
                        <div className="item_count"><span id="counter">{numResults}</span> {t('results')}
                        </div>
                        <Badge className="toast fade hide" variant="info">{numResults}&nbsp;{t('results match your criteria')}</Badge>
                      </div>
                    <div className="col">
                      <div className="filter">
                          <div className="d-flex flex-wrap d-print-none">
                            <div className="col-md-12">
                              <Toast show={show_toast} onClose={this.toggleShowToast}>
                               <Toast.Header
                                className="justify-content-between">
                                  {t('Added to Clipboard!')}
                               </Toast.Header>
                               <Toast.Body>
                                  <Form.Control
                                    type="text"
                                    readOnly
                                    value={window_location}
                                  />
                                  <Form.Text>
                                    {t('Highlight text above and copy if clipboard is empty')}
                                  </Form.Text>
                               </Toast.Body>
                             </Toast>
                             <div
                               className="pull-right pointer white "
                               onClick={this.handleClearAll}>
                                 {t('Clear All')}
                             </div>
                              <div
                                className="pull-right pointer white mr-2"
                                onClick={this.handleShare}>
                                  {t('Share')}
                              </div>

                            </div>
                          </div>
                          <div className="d-flex flex-wrap">
                            <div className="col-md-12">
                            <label
                              className="mt-0"
                              htmlFor="text_search">{t('Search')}</label>
                              <Form.Control
                                type="text"
                                name="text_search"
                                id="text_search"
                                defaultValue={text_search}
                                onChange={this.onTextSearchChange}
                                placeholder={t('Search')}
                                />
                            </div>
                          </div>
                          <div className="d-flex flex-wrap">

                            <div className="col-md-12">
                              <label htmlFor="cm_standards">{t('Standards')}</label>
                              <Select
                              hideSelectedOptions={false}
                              name="cm_standards"
                              isMulti="true"
                              closeMenuOnSelect={false}
                              blurInputOnSelect={false}
                              inputValue={cm_standards_input_value}
                              onInputChange={(input, reason) => this.onInputChange(input, reason,'cm_standards')}
                              value={cm_standards_select_values}
                              getOptionLabel={renderStandardOption}
                              getOptionValue={option => option.id}
                              onChange={this.handleSelectChange}
                              options={cm_standards}
                              isOptionDisabled={(option) => option.disabled === 'yes'}
                              filterOption={createFilter(standardFilterConfig)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="filter">
                          <div className="d-flex flex-wrap">
                            <div className="col-md-4 p-0">
                              <div className="d-flex flex-wrap">
                                <div className="col-md-12">
                                  <label>{t('Degree of Compliance')}</label>
                                  <div className="row-seven-padding">
                                      <div className="col">
                                      <BootstrapSwitchButton
                                        checked={-1!==selected_strengths.indexOf('shall')}
                                        onstyle="danger"
                                        offstyle="dark"
                                        onlabel={t('ON')}
                                        offlabel={t('OFF')}
                                        onChange={(checked: boolean) => {
                                          this.handleDegreesOfCompliance('shall',checked);
                                        }}
                                      /><br/>
                                      <Badge className="mt-2" variant="danger">{t('Shall')}</Badge>
                                      </div>
                                      <div className="col">
                                      <BootstrapSwitchButton
                                        checked={-1!==selected_strengths.indexOf('should')}
                                        onstyle="success"
                                        offstyle="dark"
                                        onlabel={t('ON')}
                                        offlabel={t('OFF')}
                                        onChange={(checked: boolean) => {
                                          this.handleDegreesOfCompliance('should',checked);
                                        }}
                                      /><br/>
                                      <Badge className="mt-2" variant="success">{t('Should')}</Badge>
                                      </div>
                                      <div className="col">
                                      <BootstrapSwitchButton
                                        checked={-1!==selected_strengths.indexOf('may')}
                                        onstyle="info"
                                        offstyle="dark"
                                        onlabel={t('ON')}
                                        offlabel={t('OFF')}
                                        onChange={(checked: boolean) => {
                                          this.handleDegreesOfCompliance('may',checked);
                                        }}
                                      /><br/>
                                      <Badge className="mt-2" variant="info">{t('May')}</Badge>
                                      </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4  p-0">
                                <div className="d-flex flex-wrap">
                                  <div className="col-md-12">
                                  { 0 < cm_topics.length &&
                                    <>
                                      <label htmlFor="cm_topics">{t('Topics')}</label>
                                      <Select
                                      hideSelectedOptions={false}
                                      name="cm_topics"
                                      isMulti="true"
                                      closeMenuOnSelect={false}
                                      blurInputOnSelect={false}
                                      inputValue={cm_topics_input_value}
                                      onInputChange={(input, reason) => this.onInputChange(input, reason,'cm_topics')}
                                      value={cm_topics_select_values}
                                      getOptionLabel={option => option.title}
                                      getOptionValue={option => option.id}
                                      onChange={this.handleSelectChange}
                                      options={cm_topics}
                                      isOptionDisabled={(option) => option.disabled === 'yes'}
                                      />
                                    </>
                                  }
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap">
                                  <div className="col-md-12">
                                  { 0 < cm_stakeholders.length &&
                                    <>
                                      <label htmlFor="cm_stakeholders">{t('Stakeholders')}</label>
                                      <Select
                                      hideSelectedOptions={false}
                                      name="cm_stakeholders"
                                      isMulti="true"
                                      closeMenuOnSelect={false}
                                      blurInputOnSelect={false}
                                      inputValue={cm_stakeholders_input_value}
                                      onInputChange={(input, reason) => this.onInputChange(input, reason,'cm_stakeholders')}
                                      value={cm_stakeholders_select_values}
                                      getOptionLabel={option => option.title}
                                      getOptionValue={option => option.id}
                                      onChange={this.handleSelectChange}
                                      options={cm_stakeholders}
                                      isOptionDisabled={(option) => option.disabled === 'yes'}

                                      />
                                    </>
                                  }
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 p-0">
                              <div className="d-flex flex-wrap">
                                <div className="col-md-12">
                                  { 0 < cm_qmps.length &&
                                    <>
                                      <label htmlFor="cm_qmps">{t('QM Principle')}</label>
                                      <Select
                                      hideSelectedOptions={false}
                                      name="cm_qmps"
                                      isMulti="true"
                                      closeMenuOnSelect={false}
                                      blurInputOnSelect={false}
                                      inputValue={cm_qmps_input_value}
                                      onInputChange={(input, reason) => this.onInputChange(input, reason,'cm_qmps')}
                                      value={cm_qmps_select_values}
                                      getOptionLabel={option => option.title}
                                      getOptionValue={option => option.id}
                                      onChange={this.handleSelectChange}
                                      options={cm_qmps}
                                      isOptionDisabled={(option) => option.disabled === 'yes'}
                                      />
                                    </>
                                  }
                                </div>
                              </div>
                              <div className="d-flex flex-wrap">
                                <div className="col-md-12">
                                  { 0 < cm_rmes.length &&
                                  <>
                                    <label htmlFor="cm_rmes">{t('RM Element')}</label>
                                    <Select
                                    hideSelectedOptions={false}
                                    name="cm_rmes"
                                    isMulti="true"
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    inputValue={cm_rmes_input_value}
                                    onInputChange={(input, reason) => this.onInputChange(input, reason,'cm_rmes')}
                                    value={cm_rmes_select_values}
                                    getOptionLabel={option => option.title}
                                    getOptionValue={option => option.id}
                                    onChange={this.handleSelectChange}
                                    options={cm_rmes}
                                    isOptionDisabled={(option) => option.disabled === 'yes'}
                                    />
                                  </>
                                  }
                                </div>
                              </div>
                              </div>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
                }

                  { initiallyLoaded ?
                    <>

                    <div className="container">

                       <div className="col">
                          <Button
                            className="pull-right d-print-none hasSpinner"
                            variant="primary"
                            size="sm"
                            onClick={(e) => this.handleSaveAllClick(e)}
                            disabled={this.state.save_all_updating}
                          >
                            {t('Save all')}
                            {(this.state.save_all_updating) && <ButtonSpinner/>}
                          </Button>
                          <div
                            ref={this.upperPaginationRef}>
                            <RequirementsPagination onPageSizeChange={this.pageSizeEventHandler} onItemClick={this.paginationEventHandler} page={this.state.page} pages_total={this.state.pagesTotal} page_size={page_size}/>
                          </div>
                          <div
                            ref={this.popOverContainerRef}
                            >
                            <div className="flex-table header " role="rowgroup">
                              <SortableTableHeader addClass="module" onHeaderClick={this.handleSortColumn} field="module_number" label={t('DocType') + ' #'} sort_column={sort_column} sort_type={sort_type}/>
                              <SortableTableHeader addClass="section" onHeaderClick={this.handleSortColumn} field="section" label={t('Section')} sort_column={sort_column} sort_type={sort_type}/>
                              <div className="flex-row importance" role="columnheader">
                                <strong>
                                  {t('Degree of Compliance')}
                                </strong>
                              </div>
                              <SortableTableHeader addClass="text flex-grow-1" onHeaderClick={this.handleSortColumn} field="text" label={t('Text')} sort_column={sort_column} sort_type={sort_type}/>
                              <div className="flex-row actions" role="columnheader">
                                <strong>
                                  {t('Save')}
                                </strong>
                              </div>
                            </div>
                            {
                            0 < requirements.length ?
                              requirements.map((value, index) => {
                                return (
                                        <div
                                          className="flex-table table-row pointer"
                                          role="rowgroup"
                                          key={ index + 'data' }
                                          onClick={() => {
                                            //need to set a timestamp just in case the same row is clicked twice
                                            this.setState({
                                              selected_req_id : value.id,
                                              selected_req_id_timestamp : new Date().valueOf()
                                            });
                                          }}>
                                            <div
                                              className="flex-row module"
                                              role="cell"
                                            >
                                            <>
                                             <div className="hidden-tablet-landscape">
                                               <OverlayTrigger
                                                 key={value.id}
                                                 placement="top"
                                                 overlay={
                                                   <Popover>
                                                     <Popover.Content>
                                                       <pre>{value.standard.title}</pre>
                                                     </Popover.Content>
                                                   </Popover>
                                                 }
                                                 >
                                                 <span>{value.standard_number}</span>
                                               </OverlayTrigger>
                                             </div>
                                             <div className="visible-tablet-landscape">
                                               <div>{t('Standard')}</div>
                                               <span><strong>{value.standard_number}</strong>   {value.standard.title}</span>
                                             </div>
                                           </>
                                           </div>
                                            <div
                                              className="flex-row section"
                                              role="cell"
                                            >{value.section}</div>
                                            <div
                                              className="flex-row importance"
                                              role="cell"
                                            >
                                              {
                                              value.importance.split(',').map((item) => <Badge {...this.badgeVariant(item)} key={item}>{t(item.trim())}</Badge>)
                                              }
                                            </div>
                                            <div
                                              className="flex-row text flex-grow-1"
                                              role="cell"
                                            >
                                              <>
                                                <div className="hidden-tablet-landscape">
                                                <OverlayTrigger
                                                  key={`text${value.id}`}
                                                  placement="top"
                                                  overlay={
                                                    <Popover className="wide">
                                                      <Popover.Content>
                                                          <pre className="md-table">
                                                            <MarkdownView
                                                              markdown={value.text}
                                                              options={{ tables: true, emoji: false }}
                                                              extensions={this.noMorePsExt}
                                                            />
                                                          </pre>
                                                      </Popover.Content>
                                                    </Popover>
                                                  }
                                                  >
                                                  <span className="md-table clamp-col">
                                                    <MarkdownView
                                                      markdown={value.text}
                                                      options={{ tables: true, emoji: false }}
                                                      extensions={this.noMorePsExt}
                                                    />
                                                  </span>
                                                </OverlayTrigger>
                                                </div>
                                                <div className="visible-tablet-landscape md-table clamp-col">
                                                  <MarkdownView
                                                  markdown={value.text}
                                                  options={{ tables: true, emoji: false }}
                                                  extensions={this.noMorePsExt}
                                                  />
                                                </div>
                                              </>
                                            </div>
                                            <div
                                              className="flex-row actions"
                                              role="cell"
                                            >
                                            <>
                                            <Button
                                              className="w-100 hasSpinner"
                                              variant="primary"
                                              size="sm"
                                              onClick={(e) => this.showListSaveToForm(e,value.id)}
                                              disabled={this.state.row_updating[value.id] || false}>
                                                {t('Save')}
                                                {(this.state.row_updating[value.id] || false) && <ButtonSpinner/>}
                                            </Button>

                                            { canViewLinkedRequirements && SiteConfig.siteTag !== 'iddrs' &&
                                              <Button
                                                className="mt-2 w-100"
                                                variant="success"
                                                size="sm"
                                                onClick={(e) => this.navigateToLinkPage(e,value.id)}
                                                disabled={!value.hasLink}>
                                                  {t('Links')}
                                              </Button>
                                            }
                                          </>
                                          </div>
                                        </div>)
                                      ;
                              })
                              : <div className="flex-table table-row w-100">
                                <div className="flex-row flex-grow-1">{t('No requirements')}</div>
                              </div>
                            }
                          </div>

                    </div>
                    </div>
                    <div className="container d-print-none">
                      <div className="col pt-2 mt-2">
                        <Button
                          className="pull-right hasSpinner"
                          variant="primary"
                          size="sm"
                          onClick={(e) => this.handleSaveAllClick(e)}
                          disabled={this.state.save_all_updating}
                        >
                          {t('Save all')}
                          {(this.state.save_all_updating) && <ButtonSpinner/>}
                        </Button>
                        <RequirementsPagination
                          onPageSizeChange={this.lowerPageSizeEventHandler}
                          onItemClick={this.lowerPaginationEventHandler}
                          page={this.state.page}
                          pages_total={this.state.pagesTotal}
                          page_size={page_size}/>
                      </div>
                    </div>
                    <RequirementDetailsModal req_id={this.state.selected_req_id} req_id_timestamp={this.state.selected_req_id_timestamp}/>
                  </>
                :
                 <LoadingSpinner heightOffset={0} />
              }
              </>
            }
          </Translation>
        );
      }
    }
  }

export default RequirementsGrid;
