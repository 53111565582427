
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import Helpers from '../components/Helpers'
import { SiteConfig }  from '../SiteConfig';
import HubSpotComponent from '../components/HubSpotComponent';

function FAQS() {
    const { t } = useTranslation();

    const {createMarkup} = Helpers();

    return <div>
            <Helmet>
                <title>{t('FAQS')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Frequently Asked Questions')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
                <span dangerouslySetInnerHTML={createMarkup(t('FAQ Page'))}/>
              </div>
            </div>
            <HubSpotComponent isProductionEnvironment={SiteConfig.isProductionEnvironment}/>
          </div>
}

export default FAQS;
